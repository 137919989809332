import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';


export const getRTKErrorMessage = (error: FetchBaseQueryError | SerializedError) => {
  let errorMessage: string | undefined = undefined;
  if ('message' in error) {
    errorMessage = error.message;
  } else if ('data' in error) {
    if (typeof error.data === 'string') {
      errorMessage = error.data;
    } else if (typeof error.data === 'object' && error.data && 'error' in error.data
      && typeof error.data.error === 'string') {
      errorMessage = error.data.error;
    }
  }
  errorMessage = errorMessage || 'An error occurred';

  return errorMessage;
};

export type Trigger<T, U> = (arg: T) => Promise<{ 
  data?: U | undefined;
  error?: FetchBaseQueryError | SerializedError | undefined;
}>;
