import { CabButton } from "@CabComponents/CabButton";
import { 
  Box, Container, Link, List, ListItem, ListItemSecondaryAction, ListItemText, Typography 
} from "@mui/material";
import {
  AppCrmContactFieldTemplatesDestroyApiArg,
  AppCrmContactFieldTemplatesPartialUpdateApiArg,
  ContactFieldTemplate,
  CompanyCategory,
  LeaderPrivateContacts
} from "../../../store/cabinetApi/generated/crm";
import { useMemo, useState } from "react";
import { CabModal } from "@CabComponents/CabModal";
import { Trigger, getRTKErrorMessage } from "../../../store/utils";
import { useFieldArray, useForm } from "react-hook-form";
import { CabDropdown } from "@CabComponents/CabDropdown";
import { FormController } from "../../../utils/formUtils";
import { CabTextInput } from "@CabComponents/CabTextInput";
import { CabIcon } from "@CabComponents/CabIcon";
import { IoAdd, IoClose } from "react-icons/io5";
import CabCard from "@CabComponents/CabCard";
import colors from "../../../colors";
import PeopleTags from "./CRMLabel";
import { ContactDenorm, Leader, User } from "../../../store";
import { PrivateContacts } from "./PrivateContacts";
import { CabAutocomplete } from "@CabComponents/CabAutocomplete";
import { useDebouncedCallback } from "use-debounce";
import InfoToolTip from "../../../components/Common/InfoToolTip";
import CabSpinner from "@CabComponents/CabSpinner";
import { CabTabs } from "@CabComponents/CabTabs";



const fieldDataTypeMap = {
  short_text: 'Text (short)',
  long_text: 'Text (long)',
  date_type: 'Date',
  single_select: 'Single Select',
  multi_select: 'Multi Select',
  file_select: 'File Select',
};

interface Props {
  fieldTemplates: ContactFieldTemplate[];
  onAddFieldTemplate: Trigger<ContactFieldTemplate, ContactFieldTemplate>;
  onUpdateFieldTemplate: Trigger<AppCrmContactFieldTemplatesPartialUpdateApiArg, ContactFieldTemplate>;
  onDeleteFieldTemplate: Trigger<AppCrmContactFieldTemplatesDestroyApiArg, unknown>;
  organizationContactCategories: { [key: number]: CompanyCategory}
  isUpdatingContactOrgCategory: boolean;
  isCreatingContactOrgCategory: boolean;
  isDeletingContactOrgCategory: boolean;
  createCompanyCategory: (data: CompanyCategory) => Promise<void>;
  updateCompanyCategory: (data: CompanyCategory) => Promise<void>;
  deleteCompanyCategory: (data: CompanyCategory) => Promise<void>;
  user: User | null | undefined;
  leaderPrivateContacts: LeaderPrivateContacts[];
  onAddPrivateContact: (leaderId: number, contactId: number) => Promise<void>;
  onRemovePrivateContact: (leaderId: number, contactId: number) => Promise<void>;
  leaders: Leader[];
  foundContacts: ContactDenorm[];
  isLoadingPrivateContacts: boolean;
  onSearchContacts: (query: string) => void;
}

type ContactFieldTemplateCreate = Omit<ContactFieldTemplate,
'id'|'organization'|'created_at'|'updated_at'|'created_by'|'updated_by'>;

const tabs = [{
  label: 'Personal',
  id: 'personal',
}, {
  label: 'My Organization',
  id: 'org',
}];

const PeopleTab = ({
  fieldTemplates, onAddFieldTemplate, onUpdateFieldTemplate, onDeleteFieldTemplate, onSearchContacts,
  isUpdatingContactOrgCategory, isCreatingContactOrgCategory, isDeletingContactOrgCategory, foundContacts,
  createCompanyCategory, updateCompanyCategory, organizationContactCategories, isLoadingPrivateContacts,
  deleteCompanyCategory, user, leaderPrivateContacts, onAddPrivateContact, onRemovePrivateContact, leaders, 
}: Props) => {
  const [showAddEditTemplateModal, setShowAddEditTemplateModal] = useState<boolean | number>(false);
  const [addPrivateContactLeaderId, setAddPrivateContactLeaderId] = useState<number | null>(null);
  const [isSavingPrivateContact, setIsSavingPrivateContact] = useState(false);

  const contactOptions = useMemo(() => {
    if (addPrivateContactLeaderId == null) return [];
    const activeLeaderContacts = leaderPrivateContacts.find(lpc => lpc.id === addPrivateContactLeaderId);
    return foundContacts.filter(c => !activeLeaderContacts?.private_contacts.find(pc => pc.id === c.id));
  }, [addPrivateContactLeaderId, foundContacts, leaderPrivateContacts]);

  const handleAddFieldTemplate = async (contact: ContactFieldTemplateCreate) => {
    const data: ContactFieldTemplateCreate = {
      label: contact.label,
      value_type: contact.value_type,
      contact_field_choices: ['single_select', 'multi_select'].includes(contact.value_type)
        && contact.contact_field_choices.length > 0 ? contact.contact_field_choices : [],
    };

    const res = await onAddFieldTemplate({
      // TODO: remove these once backend is fixed
      id: -1, organization: 1,
      ...data,
    });
    if ('error' in res && res.error) {
      console.error(getRTKErrorMessage(res.error));
    } else {
      setShowAddEditTemplateModal(false);
    }
  };

  const handleEditFieldTemplate = async (contact: ContactFieldTemplateCreate) => {
    if (typeof showAddEditTemplateModal !== 'number') return;

    const data = {
      label: contact.label,
      value_type: contact.value_type,
      contact_field_choices: ['single_select', 'multi_select'].includes(contact.value_type)
        && contact.contact_field_choices.length > 0 ? contact.contact_field_choices : [],
    };

    const res = await onUpdateFieldTemplate({
      id: showAddEditTemplateModal,
      patchedContactFieldTemplate: data,
    });
    if ('error' in res && res.error) {
      console.error(getRTKErrorMessage(res.error));
    } else {
      setShowAddEditTemplateModal(false);
    }
  };

  const handleAddPrivateContact = async (leaderId: number, contactId: number): Promise<void> => {
    setIsSavingPrivateContact(true);
    await onAddPrivateContact(leaderId, contactId);
    setIsSavingPrivateContact(false);
    setAddPrivateContactLeaderId(null);
    onSearchContacts("");
  };

  return (
    <CabTabs tabs={tabs} tabPanelSx={{ marginTop: 2 }}>
      <Container>
        <Box display='flex' gap={.5} alignItems={"center"}>
          <Typography variant="h1" sx={{ marginLeft: 4 }}>Private Contacts</Typography>
          <InfoToolTip 
            message={`Contacts added as Private for a team member will be hidden from Relationships. ` +
              `Only the teammate themselves or anyone with permission to manage Private ` +
              `Contacts on behalf of that teammate will still see the Contact's details.`}
          />
        </Box>
        <Box margin={4} marginTop={2} paddingBottom={6}>
          {!leaders.length 
            ? <CabSpinner scale={1} sx={{display: 'flex', justifyContent: 'center'}}/>
            : leaders.map(leader => (
              <PrivateContacts
                key={leader.id}
                leader={leader}
                leaderPrivateContacts={leaderPrivateContacts.find(pc => pc.id === leader.id)?.private_contacts || null} 
                onSetLeaderIdForAddContact={(leaderId) => setAddPrivateContactLeaderId(leaderId)} 
                onRemovePrivateContact={onRemovePrivateContact}
                isLoading={isLoadingPrivateContacts}
              />
            ))}
        </Box>
      </Container>

      <Container>
        <Typography variant="h1" sx={{ marginLeft: 4 }}>Organization Settings</Typography>
        <Box margin={4} marginTop={2}>
          <CabCard headerContent="Contact Field Templates">
            <List>
              {fieldTemplates.map(template => (
                <ListItem key={template.id} divider>
                  <ListItemText
                    primary={(
                      <Link
                        href="#"
                        onClick={() => setShowAddEditTemplateModal(template.id)}
                        color={colors.black900}
                      >
                        {template.label}
                      </Link>
                    )}
                    secondary={
                      `${fieldDataTypeMap[template.value_type]}
                      ${template.contact_field_choices.length
                  ? `| ${template.contact_field_choices.length} choices` : ''}`
                    }
                  />
                  <ListItemSecondaryAction>
                    <CabIcon onClick={() => onDeleteFieldTemplate(template.id)} sx={{ fontSize: 24 }} Icon={IoClose} />
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>

            <CabButton
              onClick={() => setShowAddEditTemplateModal(true)}
              sx={{ marginTop: 2 }}
              icon={<CabIcon Icon={IoAdd} />}
            >
              Add Field Template
            </CabButton>
          </CabCard>
        </Box>
        <Box margin={4}>
          <CabCard headerContent="Contact Organization Categories">
            {user ? <PeopleTags
              organizationContactCategories={organizationContactCategories}
              user={user}
              createLabel={createCompanyCategory}
              updateLabel={updateCompanyCategory}
              saving={isCreatingContactOrgCategory || isUpdatingContactOrgCategory || isDeletingContactOrgCategory}
              deleteLabel={deleteCompanyCategory}
            /> : <></>}
          </CabCard>
        </Box>
      </Container>

      {showAddEditTemplateModal !== false && (
        <AddFieldTemplateModal
          open={true}
          onClose={() => setShowAddEditTemplateModal(false)}
          onSubmit={typeof showAddEditTemplateModal === 'number' ? handleEditFieldTemplate : handleAddFieldTemplate}
          contact={typeof showAddEditTemplateModal === 'number'
            ? fieldTemplates.find(ft => ft.id === showAddEditTemplateModal)
            : undefined}
        />
      )}

      {addPrivateContactLeaderId &&
        <AddPrivateContactModal
          open={true}
          onClose={() => setAddPrivateContactLeaderId(null)}
          onSubmit={handleAddPrivateContact}
          leaderId={addPrivateContactLeaderId}
          contacts={contactOptions}
          onSearchContacts={onSearchContacts}
          isSaving={isSavingPrivateContact}
        />
      }
    </CabTabs>
  );
};

const AddFieldTemplateModal = ({ open, onClose, onSubmit, contact }: {
  open: boolean; onClose: () => void; onSubmit: (contact: ContactFieldTemplateCreate) => void;
  contact?: ContactFieldTemplate,
}) => {
  const { control, handleSubmit, watch, formState } = useForm<ContactFieldTemplateCreate>({ defaultValues: {
    label: contact?.label || '',
    value_type: contact?.value_type || 'short_text',
    contact_field_choices: contact?.contact_field_choices || [],
  } });

  const { fields, append, remove } = useFieldArray({ control, name: 'contact_field_choices' });

  const valueType = watch('value_type');

  return (
    <CabModal open={open} title={`${contact ? 'Edit' : 'New'} Field Template`}
      actionButtons={<>
        <CabButton
          buttonType="tertiary"
          onClick={onClose}
        >
          Cancel
        </CabButton>
        <CabButton onClick={handleSubmit(onSubmit)} disabled={!formState.isValid}>
          {contact ? 'Save' : 'Add'}
        </CabButton>
      </>}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <FormController name="label" label="Label" control={control} rules={{ required: true }}
          render={({ field: { ref, ...field }}) => (
            <CabTextInput inputRef={ref} {...field} />
          )}
        />

        <FormController name="value_type" label="Type" control={control} rules={{ required: true }}
          render={({ field: { ref, ...field }}) => (
            <CabDropdown<ContactFieldTemplate['value_type']> {...field}
              options={[
                { value: "short_text", label: fieldDataTypeMap['short_text'] },
                { value: "long_text", label: fieldDataTypeMap['long_text'] },
                { value: "date_type", label: fieldDataTypeMap['date_type'] },
                { value: "single_select", label: fieldDataTypeMap['single_select'] },
                { value: "multi_select", label: fieldDataTypeMap['multi_select'] },
              ]}
            />
          )}
        />

        <Box>
          {/* This controller is conditionally rendered for select types and only used for validation */}
          {['single_select', 'multi_select'].includes(valueType) && (
            <FormController name="contact_field_choices" label="Choices"
              control={control}
              rules={{
                required: true,
                validate: {
                  notZero: (choices) => choices.length > 0 ? true : 'Choices are required for this template type'
                },
              }}
              render={({ field: { ref, ...field }  }) => (
                <></>
              )}
            />
          )}

          {fields.map((choice, index) => (
            <FormController key={choice.id} name={`contact_field_choices.${index}.label`} label=""
              control={control} rules={{ required: true }} render={({ field: { ref, ...field }  }) => (
                <Box display="flex" gap={1}>
                  <CabTextInput inputRef={ref} {...field} fullWidth />
                  <CabIcon Icon={IoClose} onClick={() => remove(index)} />
                </Box>
              )}
            />
          ))}
        </Box>

        {['single_select', 'multi_select'].includes(valueType) && (
          <CabButton
            buttonType="tertiary"
            onClick={() => append({
              id: -(fields.length + 1),
              label: '',
              // TODO: remove this
              contact_field_template: -1
            })}
            icon={<CabIcon Icon={IoAdd} />}
          >
            Add Choice
          </CabButton>
        )}
      </Box>
    </CabModal>
  );
};

const AddPrivateContactModal = ({ open, onClose, onSubmit, leaderId, contacts, onSearchContacts, isSaving }: {
  open: boolean; 
  onClose: () => void; 
  onSubmit: (leaderId: number, contactId: number) => Promise<void>;
  leaderId: number;
  contacts: ContactDenorm[];
  onSearchContacts: (query: string) => void;
  isSaving: boolean;
}) => {
  const [contactId, setContactId] = useState<number | null>(null);

  const debouncedSearchContacts = useDebouncedCallback(async (query: string) => {
    onSearchContacts(query);
  }, 500);

  const options = contacts
    .filter(c => c.name || c.primary_email)
    .map(c => ({label: c.name ? `${c.name} (${c.primary_email})` : c.primary_email || "", value: c.id}));

  return (
    <CabModal open={open} title={"Add Private Contact"}
      actionButtons={<>
        <CabButton
          buttonType="tertiary"
          onClick={onClose}
        >
          Cancel
        </CabButton>
        <CabButton onClick={() => contactId && onSubmit(leaderId, contactId)} disabled={!contactId || isSaving}>
          Save
        </CabButton>
      </>}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        Search for a contact by name or email
        <CabAutocomplete<number, never>
          placeholder="Contact Name or Email"
          value={contactId}
          hideArrow
          //onFocus={event => event.target.select()}
          onChange={(value) => setContactId(value as number)}
          onInputChange={(value) => debouncedSearchContacts(value)}
          options={options}
          noOptionsText="No contacts found with that name or email"
          blurOnSelect={'mouse'}
        />
      </Box>
    </CabModal>
  );
};

export default PeopleTab;