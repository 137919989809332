import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, ThunkDispatchType, actions } from "../../../../store";
import Locations from "./Locations";


const LocationsContainer = () => {
  const presetLocations = useSelector((state: RootState) => state.schedule.presetLocations);
  const dispatch = useDispatch<ThunkDispatchType>();

  useEffect(() => {
    dispatch(actions.schedule.fetchPresetLocations());
  }, [dispatch]);

  const handleDeletePresetLocation = (id: number) => {
    dispatch(actions.schedule.deletePresetLocation(id));
  };

  const handleUpdatePresetLocation =  (id: number, name: string, location: string) => {
    dispatch(actions.schedule.updatePresetLocation(id, name, location));
  };

  const handleCreatePresetLocation = (name: string, location: string) => {
    dispatch(actions.schedule.createPresetLocation(name, location));
  };

  const handleSendUpdateMessage = () => {
    dispatch(actions.globalMessage.sendMessage({
      timeout: 2000,
      message: 'Settings have been updated',
      autoDismiss: true,
      header: '',
      position: { horizontal: 'center', vertical: 'bottom' },
      active: true,
      severity: "info",
    }));
  };


  return (
    <Locations
      presetLocations={presetLocations}
      handleDeletePresetLocation={handleDeletePresetLocation}
      handleUpdatePresetLocation={handleUpdatePresetLocation}
      handleCreatePresetLocation={handleCreatePresetLocation}
      handleSendUpdateMessage={handleSendUpdateMessage}
    />
  );
};

export default LocationsContainer;