import { ActionType } from '../actionTypes';
import { Leader } from '../leaders/types';
import { AccountOwner, User } from '../auth/types';
import { BILLING_INTERVAL, TIER, USER_ROLE } from '../../constants';

export interface Organization {
  id: number;
  name: string | null;
  logo: string | null;
  num_eas: '0' | '1' | '2-5' | '6-10' | '11-30' | '31-100' | '101+' | 'Unknown' | null
  licenses?: {[id: number]: OrganizationLicense}
  license_count?: {
    pro: number
    essentials: number
    individual: number
    starter: number
    growth: number
    premier: number
  }
  admins?: User['id'][];
  org_code?: string | null;
  cognito_provider_name?: string | null;
  owner: AccountOwner
  is_on_contract: boolean
  trialing: boolean
  active: boolean
  unpaid_trial_end_date: string | null
  onboarding_call_scheduled: boolean
  idle_timeout: number | null
  logout_redirect_uri: string | null
}

export enum ORGANIZATION_PERMISSION {
  ADMIN = 1,
  BILLING = 2,
  MANAGER = 3,
  ANALYTICS_LABELS = 4 
}

export type OrganizationLicense = {
  id: number;
  name: string;
  email: string;
  organization: number;
  user: number | null;
  user_role: USER_ROLE;
  is_active: boolean;
  tier: TIER;
  license_tier: number;
  assists_leaders?: (Leader & { leader_user: number })[];
  org_permissions_groups: ORGANIZATION_PERMISSION[];
  onsignup_assistant?: User['id'] | null;
  onsignup_leaders?: Leader['id'][];
  created_by: User['id'] | null;
};

export type OrganizationLicenseCreate = OrganizationLicense & {
  assistant?: number;
  leader?: number;
  individuals?: number[];
};

export interface OrganizationSubmission extends Partial<Organization> {
  id: Organization['id'];
  resample_type?: string
  remove_background?: boolean
  logo_file?: File | null
}






export interface OrgUserAutocompleteOption {
  value: number;
  label: string;
}

interface InvoiceLineItem {
  description: string,
  amount: number,
  discount_total: number,
  tax_total: number,
  amount_excluding_tax: number,
  period: {
    start: string,
    end: string,
  }
}

export interface SubscriptionDetails {
  is_owner: boolean,
  has_customer_account: boolean,
  tier: TIER,
  quantity: number,
  interval: BILLING_INTERVAL,
  // The amount here is the base cost of the plan, which does not include taxes, so it will not match
  // the amount shown on invoices
  amount: number,
  cancel_at: string | null,
  canceled_at: string | null,
  self_service: boolean,
  changes_preview?: {
    prorations?: InvoiceLineItem[], 
    next_cycle?: InvoiceLineItem & {
      tier?: TIER,
      quantity?: number,
      interval?: BILLING_INTERVAL,
    },
    proration_date?: string,
  },
  trial_end?: string,
  scheduled_changes?: {
    date: number,
    tier?: TIER,
    interval?: BILLING_INTERVAL,
    quantity?: number
  }
}

export interface SubscriptionChange {
  tier: TIER | null,
  interval: BILLING_INTERVAL | null,
  quantity: number | null,
  prorationDate: string | null,
}

interface OrganizationStateExt {
  userAutocompleteOptions: OrgUserAutocompleteOption[];
  subscriptionDetails: SubscriptionDetails | null;
}

export type OrganizationState = Organization & OrganizationStateExt;



export type OrganizationAction = 
  { type: ActionType.FETCHED_ORG_USER_AUTOCOMPLETE_OPTIONS; options: OrgUserAutocompleteOption[] } |
  { type: ActionType.FETCHED_SUBSCRIPTION_DETAILS; subscriptionDetails: SubscriptionDetails } |
  { type: ActionType.CHANGED_SUBSCRIPTION; subscriptionDetails: SubscriptionDetails} |
  { type: ActionType.UPDATE_ORGANIZATION; org: OrganizationSubmission} |
  { type: ActionType.UPDATE_ORGANIZATION_LICENSES; licenses: OrganizationLicense[]} |
  { type: ActionType.SET_PARTIAL_ORGANIZATION; org: Partial<Organization>} |
  { type: ActionType.SET_ORGANIZATION; org: Organization};

