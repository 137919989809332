import { useSelector } from "react-redux";
import { crm } from "../../../store/cabinetApi/crm";
import { 
  AppCrmContactFieldTemplatesCreateApiArg,
  CompanyCategory,
} from "../../../store/cabinetApi/generated/crm";
import CRM from "./CRM";
import { RootState } from "../../../store";
import useContactsList from "../../../components/CRM/CRMContacts/contactsListHook";
import { useState } from "react";


const CRMSettingsContainer = () => {
  const user = useSelector((state: RootState) => state.auth.user);
  const [contactSearchQuery, setContactSearchQuery] = useState("");

  const { data: fieldTemplatesData } = crm.useAppCrmContactFieldTemplatesListQuery({ limit: 1000 });
  const { data: leaderPrivateContacts, isLoading: isLoadingPrivateContacts } = crm.useAppCrmPrivateContactsListQuery();
  const [createFieldTemplate] = crm.useAppCrmContactFieldTemplatesCreateMutation();
  const [updateFieldTemplate] = crm.useAppCrmContactFieldTemplatesPartialUpdateMutation();
  const [createFieldTemplateChoice] = crm.useAppCrmContactFieldChoicesCreateMutation();
  const [deleteFieldTemplate] = crm.useAppCrmContactFieldTemplatesDestroyMutation();
  const [addPrivateContact] = crm.useAppCrmPrivateContactsAddPrivateContactCreateMutation();
  const [removePrivateContact] = crm.useAppCrmPrivateContactsRemovePrivateContactCreateMutation();
  const contactsListData = useContactsList({ pageSize: 10, search: contactSearchQuery });


  const leaders = useSelector((root: RootState) => root.leaders.leaders);

  const [
    createCompanyCategory,
    { isLoading: isCreatingContactOrgCategory }
  ] = crm.useAppCrmCompanyCategoriesCreateMutation();
  const [
    updateCompanyCategory,
    { isLoading: isUpdatingContactOrgCategory }
  ] = crm.useAppCrmCompanyCategoriesPartialUpdateMutation();
  const [
    deleteCompanyCategory,
    { isLoading: isDeletingContactOrgCategory }
  ] = crm.useAppCrmCompanyCategoriesDestroyMutation();
  const {data} = crm.useAppCrmCompanyCategoriesListQuery({
    limit: 100,
  });
  
  const organizationContactCategories: {[key: number]: CompanyCategory} = Object.fromEntries(
    data?.data?.map(
      (category) => [category.id, category]
    )  || []
  );

  
  const handleAddFieldTemplate = async (contact: AppCrmContactFieldTemplatesCreateApiArg) => {
    const res = await createFieldTemplate(contact);
    if (contact.contact_field_choices.length && res.data && "id" in res.data) {
      // TODO: update this logic when backend support bulk endpoints
      const choicesPromise = contact.contact_field_choices.map(choice => (
        createFieldTemplateChoice({
          ...choice,
          contact_field_template: res.data.id,
        })
      ));
      await Promise.all(choicesPromise);
    }

    return res;
  };

  const handleCreateCompanyCategory = async (category: CompanyCategory) => {
    createCompanyCategory(category);
  };

  const handleUpdateCompanyCategory = async (category: CompanyCategory) => {
    updateCompanyCategory({id: category.id, patchedCompanyCategory: category});
  };

  const handleDeleteCompanyCategory = async (category: CompanyCategory) => {
    deleteCompanyCategory(category.id);
  };

  const handleAddPrivateContact = async (leaderId: number, contactId: number) => {
    addPrivateContact({ id: leaderId, addRemovePrivateContactInput: { contactId } });
  };

  const handleRemovePrivateContact = async (leaderId: number, contactId: number) => {
    removePrivateContact({ id: leaderId, addRemovePrivateContactInput: { contactId } });
  };

  const handleSearchContacts = async (query: string) => {
    setContactSearchQuery(query);
  };

  return (
    <CRM
      fieldTemplates={fieldTemplatesData?.data || []}
      onAddFieldTemplate={handleAddFieldTemplate}
      onUpdateFieldTemplate={updateFieldTemplate}
      onDeleteFieldTemplate={deleteFieldTemplate}
      isUpdatingContactOrgCategory={isUpdatingContactOrgCategory}
      isCreatingContactOrgCategory={isCreatingContactOrgCategory}
      isDeletingContactOrgCategory={isDeletingContactOrgCategory}
      user={user}
      organizationContactCategories={organizationContactCategories}
      createCompanyCategory={handleCreateCompanyCategory}
      updateCompanyCategory={handleUpdateCompanyCategory}
      deleteCompanyCategory={handleDeleteCompanyCategory}
      leaderPrivateContacts={leaderPrivateContacts || []}
      leaders={leaders}
      foundContacts={contactSearchQuery ? contactsListData.contacts : []}
      onSearchContacts={handleSearchContacts}
      onAddPrivateContact={handleAddPrivateContact}
      onRemovePrivateContact={handleRemovePrivateContact}
      isLoadingPrivateContacts={isLoadingPrivateContacts}
    />
  );
};

export default CRMSettingsContainer;