import { Box, Typography } from "@mui/material";
import { ReactElement } from "react";
import colors from "../../../colors";
import { USER_ROLE } from "../../../constants";
import { CabButton } from '../CabButton';
import { CabIcon } from '../CabIcon';
import { IoCheckmarkCircleOutline, IoPeopleOutline, IoPersonAddOutline } from "react-icons/io5";


export interface FormInput {
  userRole: USER_ROLE;
}


export interface CabCardSelectProps<T extends string|number> {
  title?: string;
  individualTitle?: string;
  assistantTitle?: string;
  options: {
    id: T;
    type: T;
    caption: string;
    details: string[];
  }[];
  value?: T;
  onChange: (userRole: T) => void;
}

const UserRoleItem = ({ type, selected, onSelect, caption, details, individualTitle, assistantTitle }: {
  type: string|number, caption: string, details: string[],
  selected: boolean, onSelect: () => void,
  individualTitle?: string; assistantTitle?: string;
}) => (
  <Box display="flex" flexDirection="column" width="100%" padding={3} height={310}
    flex={1} borderColor={selected ? colors.black900 : colors.black300} maxWidth={235} justifyContent="space-between"
    sx={{ borderWidth: 1, borderStyle: 'solid', borderRadius: 1, backgroundColor: colors.white900 }}
  >
    <Box display="flex" flexDirection="row" gap={2} width="100%" alignItems="center" overflow={'hidden'}>
      <Box display="flex" flexDirection="column" gap={2} width="100%" alignItems="center">
        <Typography variant="h2" fontSize={22} color={colors.black600}>
          {{
            individual: individualTitle,
            assistant: assistantTitle,
          }[type]}
        </Typography>
        <CabIcon Icon={type === USER_ROLE.INDIVIDUAL ? IoPersonAddOutline : IoPeopleOutline} sx={{ fontSize: 60 }} />
        <Typography variant="body1" textAlign="center" fontSize={16}>
          {caption}
        </Typography>
      </Box>
    </Box>

    {selected ? (
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" gap={1}>
        <CabIcon Icon={IoCheckmarkCircleOutline} />
        <Typography variant="body2" fontWeight={500}>
          Selected
        </Typography>
      </Box>
    ) : (
      <CabButton
        onClick={onSelect}
        buttonType="secondary"
        sx={{ color: colors.black900, backgroundColor: colors.black200, width: 170, paddingTop: 1, paddingBottom: 1 }}
      >
        Select
      </CabButton>
    )}
  </Box>
);

export const CabCardSelect = <T extends string|number>({
  options, onChange, value, individualTitle = "For Myself", assistantTitle = "For Someone Else",
}: CabCardSelectProps<T>): ReactElement => {
  return (
    <Box display="flex" flexDirection="column" width="100%" flex={1} alignItems="center">
      <Box display="flex" flexDirection="row" gap={3} marginTop={4} width="100%" flex={1} justifyContent="center">
        {options.map(option => (
          <UserRoleItem
            individualTitle={individualTitle}
            assistantTitle={assistantTitle}
            key={option.id}
            type={option.type}
            caption={option.caption}
            details={option.details}
            selected={value === option.id}
            onSelect={() => onChange(option.id)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default CabCardSelect;
