import { CabButton } from "@CabComponents/CabButton";
import CabSpinner from "@CabComponents/CabSpinner";
import { Box, Card, CardContent, Paper, Typography, Link } from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import { ReactElement } from "react";
import { Control, useForm } from "react-hook-form";
import LocationPresetWidget from "../../../../components/Schedule/LocationPresetWidget";
import colors from "../../../../colors";
import { PAGE_URL } from "../../../../constants";
import { PresetLocations } from "../../../../store";
import { isEqual } from "lodash-es";
import { isMobile } from "../../../../utils/screenSizeUtils";


export type PresetLocationFormInput = {
  locations: {
    id?: number;
    name: string;
    location: string;
  }[];
};

interface Props {
  presetLocations: PresetLocations
  handleDeletePresetLocation: (id: number) => void;
  handleUpdatePresetLocation: (id: number, name: string, location: string) => void;
  handleCreatePresetLocation: (name: string, location: string) => void;
  handleSendUpdateMessage: () => void;
}

export const Locations = ({
  presetLocations, handleDeletePresetLocation, handleUpdatePresetLocation, handleCreatePresetLocation,
  handleSendUpdateMessage
}: Props): ReactElement => {

  const {
    control, formState: { isSubmitting, isDirty }, reset, handleSubmit: handleFormSubmit
  } = useForm<PresetLocationFormInput>({
    mode: 'onBlur',
    values: { locations: Object.values(presetLocations) },
    defaultValues: { locations: Object.values(presetLocations) },
  });

  const handleSubmit = async (data: PresetLocationFormInput) => {
    const deleteIds = Object.values(presetLocations)
      .filter(loc => !data.locations.find(l => l.id === loc.id))
      .map(loc => loc.id);

    const deletes = deleteIds.map(id => handleDeletePresetLocation(id));

    // any change to array field including append or remove will mark all elements as dirty. Therefore we must
    // calculate what elements are dirty on our own.
    const dirtyData = data.locations
      .filter(loc => !deleteIds.includes(loc.id))
      .filter(loc => !loc.id || !isEqual(presetLocations[loc.id], loc));

    const createsAndUpdates = dirtyData.map(loc => {
      if (loc.id) {
        return handleUpdatePresetLocation(loc.id, loc.name, loc.location);
      } else {
        return handleCreatePresetLocation(loc.name, loc.location);
      }
    });

    // set form fields to not be dirty
    reset(undefined, { keepValues: true });

    await Promise.all([...deletes, ...createsAndUpdates]);

    handleSendUpdateMessage();
  };



  return (
    <Box>
      <Card variant="outlined" sx={{ width: '100%' }}>
        <CardContent>
          <Paper elevation={0} sx={{ padding: '5px', position: 'relative' }}>
            <form onSubmit={handleFormSubmit(handleSubmit)}>
              <Box display="flex" flexDirection="row" justifyContent="space-between" marginBottom={2}>
                <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                  Other Meeting Locations
                </Typography>
                {(isDirty || isSubmitting) && (
                  <Box display="flex" gap={1}>
                    {!isSubmitting && (
                      <CabButton
                        sx={{ height: 30 }}
                        buttonType="tertiary"
                        onClick={() => reset()}
                      >
                        Discard Changes
                      </CabButton>
                    )}
                    <CabButton type="submit" sx={{ height: 30 }} disabled={isSubmitting}>
                      {isSubmitting ? <CabSpinner color="info" scale={1} /> : 'Save Locations'}
                    </CabButton>
                  </Box>
                )}
              </Box>
              <LocationPresetWidget name="locations" control={control as unknown as Control} />
            </form>
          </Paper>
          {!isMobile() && (
            <Box display="flex" marginTop={2}>
              <Typography variant="body1">To update your Zoom settings,&nbsp;</Typography>
              <Link component={RouterLink} to={PAGE_URL.INTEGRATION_SETTINGS} variant="body1" color={colors.black900}
                fontWeight={600}>
                click here
              </Link>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default Locations;