import { 
  GridFilterModel, GridSortModel, GridToolbarColumnsButton, GridToolbarFilterButton 
} from "@mui/x-data-grid-pro";
import { 
  LeaderPermission, OrganizationLeader, ProfileCategory, SchedulingPermission, SharedLeaderGrant, User 
} from "../../../../store";
import { useBackupTableColumns } from "./backupTableColumns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CabButton, CabModal, CabTooltip } from "@CabComponents/index";
import { Box, Grid } from "@mui/material";
import ShareLeaderContainer from "../../../../components/Sharing";
import { sortBy } from "lodash-es";
import CabDataGrid from "@CabComponents/CabDataGrid";

export type BackupTableProps = {
  leader?: OrganizationLeader
  grants?: SharedLeaderGrant[]
  categories?: ProfileCategory[]
  closeBackupTable: () => void
  user: User | null | undefined
  revokeGrant: (grant: SharedLeaderGrant) => void
};

export default function BackupTable({
  grants, leader, closeBackupTable, revokeGrant,
  categories, user
}: BackupTableProps) {
  const [modalGrant, setModalGrant] = useState<SharedLeaderGrant | null>(null);
  const [openCreateShareGrantModal, setOpenCreateShareGrantModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filterState, setFilterState] = useState<GridFilterModel>();
  const [sortState, setSortState] = useState<GridSortModel>();
  const [paginationState, setPaginationState] = useState({ page: 0, pageSize: 20 });

  const handleOpenOtherPermissions = useCallback((grant: SharedLeaderGrant) => {
    setModalGrant(grant);
  }, []);

  const columns = useBackupTableColumns({
    user,
    handleOpenOtherPermissions,
    revokeGrant
  });

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
  }, [loading]);
  const categoriesMap = useMemo(() => {
    return Object.fromEntries(categories?.map((cat) => [cat.id, cat]) || []);
  }, [categories]);

  const rows = useMemo(() => (grants || []), [grants]);

  const slots = useMemo(() => ({
    toolbar: () => <Box
      display="flex" flexDirection="row" justifyContent={"space-between"} sx={{ width: "100%" }}
      paddingBottom={1}
    >
      <Box display={"flex"} minWidth={350}>
        <CabButton buttonType='tertiary' color='primary' onClick={() => closeBackupTable()} sx={{ marginRight: 1 }}>
          Go back
        </CabButton>
        <CabTooltip
          title='Requires "Team" permission level for this organization'
        >
          <CabButton 
            disabled={!user?.permissions.WRITE_LEADER_DATA_ACCESS} type="button"
            onClick={() => setOpenCreateShareGrantModal(true)} sx={{ marginRight: 1 }}
          >
            Give Access
          </CabButton>
        </CabTooltip>
      </Box>
      <Box display="flex" flexDirection="row-reverse" sx={{width: "100%"}}>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      </Box>
    </Box>,
    noRowsOverlay: () => <Box
      height="100%"
      width="100%"
      display="flex"
      justifyContent="center"
    >
      <Box margin="auto" padding="auto">
        No Backups Available
      </Box>
    </Box>,
    noResultsOverlay: () => <Box
      height="100%"
      width="100%"
      display="flex"
      justifyContent="center"
    >
      <Box margin="auto" padding="auto">
        No Backups Found
      </Box>
    </Box>
  }), [closeBackupTable, user?.permissions.WRITE_LEADER_DATA_ACCESS]);

  return <Box height="100%">

    <CabDataGrid<SharedLeaderGrant>
      rows={rows}
      columns={columns}
      pagination
      pageSizeOptions={[20]}
      paginationModel={paginationState}
      onPaginationModelChange={setPaginationState}
      filterModel={filterState}
      onFilterModelChange={(data: GridFilterModel) => setFilterState(data)}
      sortModel={sortState}
      onSortModelChange={(data: GridSortModel) => setSortState(data)}
      paginationMode="client"
      filterMode="client"
      sortingMode="client"
      disableRowSelectionOnClick
      checkboxSelection={false}
      slots={slots}
    />
    <CabModal
      title={"Permission Access"}
      open={!!modalGrant}
      closeIcon={true}
      onClose={() => setModalGrant(null)}
    >
      {modalGrant &&
        <PermissionsTable
          grant={modalGrant}
          categoriesMap={categoriesMap}
        />
      }
    </CabModal>
    {leader &&
      <ShareLeaderContainer
        leader={leader}
        isOpen={openCreateShareGrantModal}
        onDidDismiss={() => setOpenCreateShareGrantModal(false)}
        isAdminUser
      />
    }
  </Box>;
}

const PermissionsTable = ({
  grant,
  categoriesMap
}: {
  grant: SharedLeaderGrant
  categoriesMap: {
    [k: string]: ProfileCategory;
  }
}) => {

  let permissionsList: [string, LeaderPermission][] = [];

  const schedulingPermList = Object.entries({
    scheduling: grant?.scheduling_permissions ? grant?.scheduling_permissions : {
      view: false, edit: false, delete: false, create: false
    } as LeaderPermission
  });
  permissionsList = permissionsList.concat(sortBy(Object.entries({
    ...grant?.permissions,
  }), (value) => value[0]));

  const catPermissionList = sortBy(Object.entries(
    grant?.category_permissions
  ), [function(value) { 
    return categoriesMap[value[0]].title;
  }]);
  
  return <Grid container>
    <Grid container item xs={12} sx={{ borderBottom: 2, paddingBottom: 1, marginBottom: 2, fontSize: 18 }}>
      <Grid item xs={3} sx={{ fontWeight: "bold" }}>Scheduling</Grid>
    </Grid>
    <Box sx={{marginBottom: 2, width: "100%"}}>
      {schedulingPermList.map(([key, value]) => (
        Object.keys(value).filter(permType => permType === 'edit' || permType === 'add_to_meeting').sort()
          .map(permType => (
            <PermissionSet
              key={permType}
              permissionKey={permType === 'edit' ? 'View/Edit Meetings' : 'Add To Meetings'}
              permission={value}
              permissionTypes={[permType as keyof SchedulingPermission]}
              categoriesMap={categoriesMap}
              grant={grant}
            />
          ))
      ))}
    </Box>
    <Grid container item xs={12} sx={{ borderBottom: 2, paddingBottom: 1, marginBottom: 2, fontSize: 18 }}>
      <Grid item xs={3} sx={{ fontWeight: "bold" }}>Profile Info</Grid>
      <Grid item xs={3} sx={{ fontWeight: "bold", textAlign: "center" }}>View</Grid>
      <Grid item xs={3} sx={{ fontWeight: "bold", textAlign: "center" }}>Edit</Grid>
    </Grid>
    <Box sx={{marginBottom: 2, width: "100%"}}>
      {permissionsList.map(([key, value]) => {
        return <PermissionSet
          key={key}
          permissionKey={key}
          permission={value}
          permissionTypes={['view', 'edit']}
          categoriesMap={categoriesMap}
          grant={grant}
        />;
      })}
    </Box>
    {catPermissionList.map(([key, value]) => {
      return <PermissionSet
        key={key}
        permissionKey={key}
        permission={value}
        permissionTypes={['view', 'edit']}
        categoriesMap={categoriesMap}
        grant={grant}
      />;
    })}
  </Grid>;
};

const PermissionSet = ({ permissionKey, permission, grant, categoriesMap, permissionTypes }: {
  permissionKey: string;
  permission: Partial<SchedulingPermission>;
  permissionTypes: (keyof SchedulingPermission)[];
  grant: SharedLeaderGrant;
  categoriesMap: {
    [k: string]: ProfileCategory;
  }
}) => {
  return (
    <Grid container key={permissionKey} item xs={12} marginBottom={2} sx={{ borderBottom: 1, paddingBottom: 1 }}>
      <Grid item xs={3} sx={{ fontWeight: 600, textTransform: "capitalize" }}>
        {(!Number.isNaN(Number(permissionKey)) && 
          categoriesMap[Number(permissionKey)]) ? categoriesMap[Number(permissionKey)].title : permissionKey}
      </Grid>
      {permissionTypes.map(permType => (
        <Grid key={permType} item xs={3} display={"flex"} justifyContent="center" >
          {(permission?.[permType] || !!grant?.is_admin) ? "Yes" : "No"}
        </Grid>
      ))}
    </Grid>
  );
};
