import { useDispatch, useSelector } from 'react-redux';
import Organization from './Users';
import {
  RootState, ThunkDispatchType, actions,
  OrganizationSubmission,
  SubscriptionDetails,
  GlobalMessage,
  OrganizationLicense
} from '../../../../store';
import { useCallback, useEffect } from 'react';
import { BILLING_INTERVAL, TIER } from '../../../../constants';

interface ComponentProps {
  paymentButtonDisabled: boolean;
  paymentDialogError: string;
  paymentRedirectDialogOpen: boolean;
  clearPaymentDialogError: () =>  void;
  onChangeSubscription: (
    newTier: TIER | null, interval: BILLING_INTERVAL | null, quantity: number | null, 
    newPromoCode: string | null, isPreview: boolean, prorationDate: string | null
  ) => Promise<SubscriptionDetails | undefined>;
}

type Props = ComponentProps;


export const OrganizationContainer = ({
  onChangeSubscription, paymentButtonDisabled, paymentDialogError, paymentRedirectDialogOpen,
  clearPaymentDialogError
}: Props) => {
  const dispatch = useDispatch<ThunkDispatchType>();
  const updateOrganization = (org: OrganizationSubmission) => 
    dispatch(actions.organization.updateOrganization(org));
  const processOrganizationLogo = (org: OrganizationSubmission) =>
    dispatch(actions.organization.processOrganizationLogo(org));
  const sendMessage = (message: GlobalMessage) => dispatch(actions.globalMessage.sendMessage(message));
  const resendInvite = useCallback((license: OrganizationLicense) => 
    dispatch(actions.organization.resendLicenseInvite(license)), [dispatch]);


  const org = useSelector((state: RootState) => (
    {...state.organization, resample_type: "nearest", remove_background: false, logo_file: null}
  ));
  const user = useSelector((state: RootState) => state.auth.user);
  const subscriptionDetails = useSelector((state: RootState) => state.organization.subscriptionDetails);
  
  const handleUpdateOrg = async (orgUpdate: OrganizationSubmission) => {
    const response = await updateOrganization(orgUpdate);
    if (response && response.status === 200) {
      sendMessage({
        timeout: 5000,
        message: `${response.data.name} has been updated`,
        header: "",
        position: {
          vertical: 'top',
          horizontal: 'center'
        },
        active: true,
        severity: 'success',
        autoDismiss: true
      });
    } else {
      sendMessage({
        timeout: 5000,
        message: `Something went wrong. Contact support@joincabinet.com`,
        header: "",
        position: {
          vertical: 'top',
          horizontal: 'center'
        },
        active: true,
        severity: 'error',
        autoDismiss: true,
      });
    }
    return response;
  };

  useEffect(() => {
    if (org.owner.id === user?.id) {
      dispatch(actions.organization.listOrganizationLicenses(org.id));
    }
  }, [org.id, org.owner.id, user?.id, dispatch]);

  
  return <Organization
    org={org}
    updateOrganization={handleUpdateOrg}
    processOrganizationLogo={processOrganizationLogo}
    accountOwner={org?.owner ?? undefined}
    user={user}
    subscriptionDetails={subscriptionDetails}
    onChangeSubscription={onChangeSubscription}
    confirmButtonDisabled={paymentButtonDisabled}
    dialogError={paymentDialogError}
    redirectDialogOpen={paymentRedirectDialogOpen}
    clearPaymentDialogError={clearPaymentDialogError}
    resendInvite={resendInvite}
  />;
};

export default OrganizationContainer;
