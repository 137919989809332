import { ReactElement} from 'react';
import { CabIcon } from '@CabComponents/CabIcon';
import { Typography } from '@mui/material';
import { IoCheckmark, IoClose } from 'react-icons/io5';

interface Props {
  password: string;
  setValid?: (valid: boolean) => void;
}

interface Rule {
  text: string;
  validFunc: (password: string) => boolean;
  alwaysVisible: boolean;
}

const rules: Rule[] = [
  {text: 'At least 8 characters long', 
    validFunc: (password: string): boolean => password.length >= 8,
    alwaysVisible: true },
];


export const PasswordRequirements = ({ password, setValid}: Props): ReactElement => {
  
  const renderValidationField = (passing: boolean, rule: Rule): ReactElement|null => {
    if (rule.alwaysVisible || !passing) {
      return <div key={rule.text}>
        <div style={{color: passing ? 'green' : 'red', display: 'flex', alignItems: 'center'}}>
          <span style={{width: '10px'}}>
            <CabIcon Icon={passing ? IoCheckmark : IoClose} size='small' sx={{color: passing ? 'green' : 'red'}}/>
          </span>
          <Typography fontSize={12} sx={{color: passing ? 'green' : 'red'}}>{rule.text}</Typography>
        </div>
      </div>;
    }
    return null;
  };

  const checkRules = (): ReactElement => {
    let valid = true;
    const fields = (
      <>
        {rules.map((rule: Rule): ReactElement|null => {
          if (rule.validFunc(password)) {
            return renderValidationField(true, rule);
          } else {
            valid = false;
            return renderValidationField(false, rule);
          }
        })}
      </>
    );
    if (setValid) {
      setValid(valid);
    }
    return fields;
  };
  
  return (
    <div>
      {checkRules()}
    </div>
  );
};

export default PasswordRequirements;
