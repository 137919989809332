import CabCollapseMenu from "@CabComponents/CabCollapseMenu";
import { SharedLeaderGrant, User } from "../../../../../store";
import { CabButton } from "@CabComponents/CabButton";
import { CabIcon } from "@CabComponents/CabIcon";
import colors from "../../../../../colors";
import { styled, useMediaQuery } from "@mui/material";
import theme from "../../../../../theme";
import { CabTooltip } from "@CabComponents/CabTooltip";
import { IoKeyOutline, IoPersonRemoveOutline, IoEllipsisHorizontal } from "react-icons/io5";

export type BackupMenuProps = {
  handleOpenOtherPermissions: (grant: SharedLeaderGrant) => void
  revokeGrant: (grant: SharedLeaderGrant) => void
  grant: SharedLeaderGrant
  user: User | null | undefined
};

const StyledGroupButton = styled(CabButton, {label: "StyledGroupButton"})({
  justifyContent: 'start', 
  color: colors.black900, 
  paddingBottom: 8, 
  paddingTop: 8, 
  paddingLeft: 15, 
  paddingRight: 15, 
  width: '100%'
});

export default function BackupMenu({user, grant, revokeGrant, handleOpenOtherPermissions}: BackupMenuProps) {
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  return <CabCollapseMenu
    popOverTitle="See More"
    buttonGroupSx={{paddingTop: 1, paddingBottom: 1, minWidth: isSmDown ? "120px" : "190px"}}
    buttonGroupColor="inherit"
    buttonGroupVariant="outlined"

    buttons={<>
      <StyledGroupButton buttonType="text"
        onClick={() => handleOpenOtherPermissions(grant)}
        icon={<CabIcon Icon={IoKeyOutline}/>}
      >
        See Permissions
      </StyledGroupButton>
      <CabTooltip
        title='Requires "Team" permission level for this organization'
      >
        <StyledGroupButton  buttonType="text"
          onClick={() => revokeGrant(grant)}
          disabled={!user?.permissions.WRITE_LEADER_DATA_ACCESS}
          icon={<CabIcon Icon={IoPersonRemoveOutline}/>}
        >
          Revoke Entry
        </StyledGroupButton>
      </CabTooltip>
    </>}
    target={<CabIcon
      Icon={IoEllipsisHorizontal}
      sx={{textAlign: "right", fontSize: 20}} 
    />}
    popOverAnchorOrigin={{
      horizontal: -100,
      vertical: 30
    }}
  />;
}