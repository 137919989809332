import CabCollapseMenu from "@CabComponents/CabCollapseMenu";
import { CabButton } from "@CabComponents/CabButton";
import { CabIcon } from "@CabComponents/CabIcon";
import { styled, useMediaQuery } from "@mui/material";
import colors from "../../../../colors";
import theme from "../../../../theme";
import { 
  IoCreateOutline, IoEllipsisHorizontal, IoPeopleCircleOutline, IoSwapHorizontalOutline, IoTrashOutline 
} from "react-icons/io5";
import { CabTooltip } from "@CabComponents/CabTooltip";

export type LeaderMenuProps = {
  onSeeBackUps: () => void;
  onDeleteLeader: () => void;
  hasEditLeaderPermission: boolean;
  onEditLeader: () => void;
  onReassignLeader: () => void;
  canTransferLeader: boolean;
  user?: { id: number; first_name: string; last_name: string; email: string };
};

const StyledGroupButton = styled(CabButton, {label: "StyledGroupButton"})({
  justifyContent: 'start',
  color: colors.black900, 
  paddingBottom: 8, 
  paddingTop: 8, 
  paddingLeft: 15, 
  paddingRight: 15, 
  width: '100%'
});

export default function LeaderMenu({
  onSeeBackUps, onDeleteLeader, hasEditLeaderPermission, onEditLeader, onReassignLeader, canTransferLeader,
  user
}: LeaderMenuProps) {
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
  return <CabCollapseMenu
    popOverTitle="See More"
    buttonGroupSx={{paddingTop: 1, paddingBottom: 1, minWidth: isSmDown ? "120px" : "190px"}}
    buttonGroupColor="inherit"
    buttonGroupVariant="outlined"
    buttons={<>
      <StyledGroupButton
        buttonType="text"
        onClick={onSeeBackUps}
        icon={<CabIcon Icon={IoPeopleCircleOutline} />}
      >
        See Backups
      </StyledGroupButton>
      {hasEditLeaderPermission && canTransferLeader && (
        <StyledGroupButton
          buttonType="text"
          onClick={onReassignLeader}
          icon={<CabIcon Icon={IoSwapHorizontalOutline} />}
        >
          Transfer
        </StyledGroupButton>
      )}
      {hasEditLeaderPermission && (
        <StyledGroupButton
          buttonType="text"
          onClick={onEditLeader}
          icon={<CabIcon Icon={IoCreateOutline} />}
        >
          Edit
        </StyledGroupButton>
      )}
      {hasEditLeaderPermission && (
        <CabTooltip
          wrapWithSpan
          title={user
            ? 'This teammate has a user account and cannot be deleted'
              + ` (${user.first_name} ${user.last_name}, ${user.email}).`
              + ' To delete this teammate, you must remove that user from your organization in the Organization tab.'
            : ''}>
          <StyledGroupButton
            buttonType="text"
            onClick={onDeleteLeader}
            icon={<CabIcon Icon={IoTrashOutline} />}
            color="error"
            disabled={!!user}
          >
            Delete
          </StyledGroupButton>
        </CabTooltip>
      )}
    </>}
    target={<CabIcon
      Icon={IoEllipsisHorizontal}
      sx={{textAlign: "right", fontSize: 20}} 
    />}
    popOverAnchorOrigin={{
      horizontal: -170,
      vertical: 30
    }}
  />;
}