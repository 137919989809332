import { CabButton } from "@CabComponents/CabButton";
import { CabIcon } from "@CabComponents/CabIcon";
import { CabInput } from "@CabComponents/CabInput";
import { CabModal } from "@CabComponents/CabModal";
import { Box, Card, styled, Typography } from "@mui/material";
import capitalize from "lodash-es/capitalize";
import { DateTime } from "luxon";
import { ReactElement, useEffect, useState } from "react";
import colors from "../../../colors";
import { BILLING_INTERVAL,  NEW_TIER, NEW_TIER_DETAILS, OLD_TIER, OLD_TIER_DETAILS, TIER } from "../../../constants";
import { SubscriptionChange, SubscriptionDetails } from "../../../store";
import { SubChangeConfirm } from "./SubChangeConfirm";
import { IoCaretUpOutline, IoCaretDownOutline } from "react-icons/io5";

interface Props {
  subscriptionDetails: SubscriptionDetails | null;
  confirmButtonDisabled: boolean;
  dialogError: string;
  fullHeight?: boolean;
  onChangeSubscription: (
    tier: TIER | null, interval: BILLING_INTERVAL | null, quantity: number | null, 
    newPromoCode: string | null, isPreview: boolean, prorationDate: string | null
  ) => Promise<SubscriptionDetails | undefined>
  clearPaymentDialogError: () =>  void;
  isOnContract: boolean;
  isStripeManager: boolean;
  quantityDialogOpen: boolean;
  handleQuantityDialogOpen: (value: boolean) => void
  onAddAdditionalLicensesCancel: () => void
  onLicenseChangeSuccess: () => void
}


export const SubSummary = ({ 
  subscriptionDetails, confirmButtonDisabled, dialogError, fullHeight, onChangeSubscription,
  clearPaymentDialogError, isOnContract, isStripeManager, quantityDialogOpen, handleQuantityDialogOpen,
  onAddAdditionalLicensesCancel, onLicenseChangeSuccess
}: Props): ReactElement => {
  const [newQuantity, setNewQuantity] = useState(1);
  const [newSubscriptionDetails, setNewSubscriptionDetails] = useState<SubscriptionChange | null>(null);

  useEffect(() => {
    if (subscriptionDetails?.quantity) {
      setNewQuantity(subscriptionDetails.quantity);
    }
  }, [subscriptionDetails?.quantity]);
   
  const tier: TIER = subscriptionDetails?.tier ?? NEW_TIER.BASIC;
  const planTitle = tier ? {...OLD_TIER_DETAILS, ...NEW_TIER_DETAILS}[tier].title : "------";
  const canceledAt = subscriptionDetails?.canceled_at;
  const cancelAt = subscriptionDetails?.cancel_at;
  const interval = subscriptionDetails?.interval ?? BILLING_INTERVAL.MONTH;
  const intervalDisplay = subscriptionDetails && tier !== 'BASIC' 
    ? `${capitalize(interval)}ly` 
    : '------';
  const quantity = subscriptionDetails && tier !== 'BASIC' 
    ?  `${subscriptionDetails.quantity}` 
    : "------";
  const selfService = !!subscriptionDetails?.self_service && !isOnContract && isStripeManager;
  

  const addQuantity = (delta: number) => {
    const updatedQuantity = newQuantity + delta;
    if (updatedQuantity > 0 && updatedQuantity < 1000) {
      setNewQuantity(updatedQuantity);
    }
  };

  let titleChange = "";
  let intervalChange = "";
  let quantityChange = "";
  if (subscriptionDetails?.scheduled_changes) {
    const scheduled = subscriptionDetails?.scheduled_changes;
    const nextDate = DateTime.fromSeconds(scheduled.date).toLocaleString();
    const nextTier = scheduled.tier ? scheduled.tier : "";
    const nextInterval = scheduled.interval && nextTier !== NEW_TIER.BASIC ? scheduled.interval : "";
    const nextQuantity = scheduled.quantity && nextTier !== NEW_TIER.BASIC ? scheduled.quantity.toString() : "";

    if (nextTier && nextTier !== tier) {
      titleChange = `(Changes to ${{...OLD_TIER_DETAILS, ...NEW_TIER_DETAILS}[nextTier]?.title} as of ${nextDate})`;
    }
    if (nextInterval && nextInterval !== interval) {
      intervalChange = `(Changes to ${capitalize(scheduled.interval)}ly as of ${nextDate})`;
    }
    if (nextQuantity && nextQuantity !== quantity) {
      quantityChange = `(Changes to ${nextQuantity} as of ${nextDate})`;
    }
  }

  const handleSetNewSubscriptionDetails = () => {
    if (subscriptionDetails) {
      setNewSubscriptionDetails({
        tier: null, 
        interval: null, 
        quantity: newQuantity,
        prorationDate: null,
      });
    }
  };

  const handleClearNewSubscriptionDetails = () => {
    setNewSubscriptionDetails(null);
  };

  const handleOnChangeSubscription: Props["onChangeSubscription"] = (
    newTier, newInterval, newLicenseQuantity, promoCode, isPreview, proRationDate
  ) => {
    const res = onChangeSubscription(newTier, newInterval, newLicenseQuantity, promoCode, isPreview, proRationDate);
    if (res === undefined) {
      handleQuantityDialogOpen(false);
    }
    return res;
  };

  const onCancelAdditionalLicenses = () => {
    handleQuantityDialogOpen(false);
    onAddAdditionalLicensesCancel();
  };

  return (
    <Card variant='outlined' sx={{marginBottom: "16px", height: fullHeight ? '100%' : 'auto' }}>
      <TitleText>
        Your Subscription Summary
      </TitleText>
      <SummarySection>
        <FlexDiv>
          <Typography variant='body1'>
            Your Plan
          </Typography>
          <Typography variant='body1'>
            {planTitle}
          </Typography>
        </FlexDiv>
        <FlexDiv>
          <Box></Box>
          {
            canceledAt &&
            <BoldText>(Canceled on {DateTime.fromISO(canceledAt).toLocaleString()})</BoldText>
          }
          {
            cancelAt &&
            <BoldText>(Until {DateTime.fromISO(cancelAt).toLocaleString()})</BoldText>
          }
          {
            titleChange &&
            <BoldText>{titleChange}</BoldText>
          }
        </FlexDiv>
      </SummarySection>
      <SummarySection>
        <FlexDiv>
          <Typography variant='body1'>
            Billing Interval
          </Typography>
          <Typography variant='body1'>
            {intervalDisplay}
          </Typography>
        </FlexDiv>
        <FlexDiv>
          <Box></Box>
          {
            intervalChange &&
            <BoldText>{intervalChange}</BoldText>
          }
        </FlexDiv>
      </SummarySection>
      <SummarySection>
        <FlexDiv>
          <Typography variant='body1'>
            Number of Licenses
          </Typography>
          <Box display={"flex"}>
            <Typography variant='body1'>
              {quantity}
            </Typography>
            {(selfService && ![
              NEW_TIER.BASIC, NEW_TIER.INDIVIDUAL, OLD_TIER.ESSENTIALS, OLD_TIER.PRO
            ].includes(tier) ) &&
              <CabButton 
                size='small'
                buttonType='secondary'
                onClick={() => handleQuantityDialogOpen(true)}
                sx={{marginLeft: "8px", padding: 1, height: 20, width: 110 }} 
              >
                Add / Remove
              </CabButton>
            }
          </Box>
        </FlexDiv>
        <FlexDiv>
          <Box></Box>
          {
            quantityChange &&
            <BoldText>{quantityChange}</BoldText>
          }
        </FlexDiv>
      </SummarySection>
      <CabModal 
        open={quantityDialogOpen}
        onClose={onCancelAdditionalLicenses}
        title="Add / Remove Licenses"
        aria-labelledby='Add or Remove Licenses'
        aria-describedby='Add or Remove Licenses'
        actionButtons={
          <FlexDiv>
            <CabButton 
              buttonType='tertiary'
              onClick={() => {
                setNewQuantity(subscriptionDetails?.quantity || 0);
                onCancelAdditionalLicenses();
                clearPaymentDialogError();
              }}
            >
              Cancel
            </CabButton>
            <CabButton
              disabled={confirmButtonDisabled || newQuantity < 1 || subscriptionDetails?.quantity === newQuantity}
              onClick={handleSetNewSubscriptionDetails}
            >
              Update Licenses
            </CabButton>
          </FlexDiv>
        }
      >
        <Box>
          <Box sx={{marginBottom: "16px"}}>
            {'How many licenses do you need?'}
          </Box>
          <Box display="flex" justifyContent={"center"}>
            <CabInput
              value={newQuantity}
              onChange={(v) => setNewQuantity(Number(v.target.value))}
              sx={{
                width: "64px",
                'input': {
                  textAlign: "center",
                  fontSize: "24px",
                  padding: "12px"
                }
              }}
            />
            <Box marginLeft={"12px"} display="flex" flexDirection={"column"} justifyContent="center">
              <ClickableIcon 
                Icon={IoCaretUpOutline} 
                onClick={() => addQuantity(1)}
                sx={{
                  opacity: newQuantity < 999 ? "100%" : "50%",
                }}
              />
              <ClickableIcon 
                Icon={IoCaretDownOutline} 
                onClick={() => addQuantity(-1)}
                sx={{
                  opacity: newQuantity > 1 ? "100%" : "50%",
                }}
              />
            </Box>
            
          </Box>
          <Box sx={{width: "100%"}}>
            {dialogError &&
              <Typography variant='body1' color="error" marginTop={"12px"}>
                {dialogError}
              </Typography>
            }
          </Box>
        </Box>
      </CabModal>
      <SubChangeConfirm
        onClose={handleClearNewSubscriptionDetails}
        buttonDisabled={confirmButtonDisabled}
        dialogError={dialogError}
        newSubscriptionDetails={newSubscriptionDetails}
        onChangeSubscription={handleOnChangeSubscription}
        onLicenseChangeSuccess={onLicenseChangeSuccess}
      />
    </Card>
  );
};

const FlexDiv = styled("div", {label: "FlexDiv"})({
  display: "flex",
  justifyContent: "space-between",
  width: "100%"
});

const BoldText = styled("span", {label: "BoldText"})({
  fontWeight: 600,
  fontSize: 12
});

const TitleText = styled("div", {label: "TitleText"})({
  padding: "12px",
  fontSize: "14px",
  fontWeight: "bold",
});

const SummarySection = styled("div", {label: "SummarySection"})({
  padding: "12px",
  borderTop: `2px solid ${colors.black50}`,
  display: "flex",
  flexDirection: "column",
});

const ClickableIcon = styled(CabIcon, {label: "ClickableIcon"})({
  fontSize: "28px",
});