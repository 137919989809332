import { ReactElement, useState } from 'react';
import { CabModal } from '@CabComponents/CabModal';
import { CabButton } from '@CabComponents/CabButton';
import { Box, FormControl, FormLabel, List, ListItem } from '@mui/material';
import { useDispatch } from 'react-redux';
import { OrgUserAutocompleteOption, OrganizationLeader, ThunkDispatchType, actions } from '../../../../store';
import { CabAutocomplete } from '@CabComponents/CabAutocomplete';
import { CabControlLabel } from '@CabComponents/CabControlLabel';
import { CabCheckbox } from '@CabComponents/CabCheckbox';

interface ComponentProps {
  leader: OrganizationLeader;
  isOpen: boolean;
  onDidDismiss: () => void;
  shareWithUserOptions: OrgUserAutocompleteOption[];
}


export const AdminReassignLeader = 
  ({ leader, isOpen, onDidDismiss, shareWithUserOptions}: ComponentProps): ReactElement => {

    const dispatch = useDispatch<ThunkDispatchType>();
    const reassignLeader = async (toUserId: number, shareWithUser: boolean) => {
      await dispatch(actions.leaders.transferAdminLeader(
        leader.id, shareWithUser, toUserId, leader.primary_assistant?.id));
    };

    const [toUserId, setToUserId] = useState<number | undefined>(undefined);
    const [shareToUser, setShareToUser] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const handleCancel = (): void => {
      onDidDismiss();
    };

    const handleSave = async () => {
      if (toUserId) {
        setSubmitting(true);
        await reassignLeader(toUserId, shareToUser);
        dispatch(actions.leaders.fetchLeaders());
        onDidDismiss();
      }
      setSubmitting(false);
    };

    return (
      <CabModal
        open={isOpen}
        onClose={handleCancel}
        closeIcon
        closeOnBackdropClick
        title={`Reassign ${leader.first_name} ${leader.last_name}`}
        actionButtons={
          <CabButton color='primary' onClick={handleSave} disabled={!toUserId || submitting}>
            Save
          </CabButton>
        }
      >
        <Box display='flex' flexDirection='column' gap={1}>
          <List
            sx={{
              listStyleType: 'disc',
              listStylePosition: 'inside'
            }}
          >
            This will transfer ownership of a Leader Profile from one account to another. 
            <br/><br/>This includes:
            <ListItem sx={{ display: 'list-item', paddingTop: 0, paddingBottom: 0 }}>
              Profile categories
            </ListItem>
            <ListItem sx={{ display: 'list-item', paddingTop: 0, paddingBottom: 0  }}>
              Meetings and scheduling settings
            </ListItem>
            <ListItem sx={{ display: 'list-item', paddingTop: 0, paddingBottom: 0  }}>
              Analytics data
            </ListItem>
            <ListItem sx={{ display: 'list-item', paddingTop: 0, paddingBottom: 0  }}>
              Ability to share the Profile with others
            </ListItem>
          </List>
          <FormControl fullWidth sx={{marginTop: 1}}>
            <FormLabel>New Primary Support*</FormLabel>
            <CabAutocomplete<number, never>
              placeholder={'Find teammates by name or email'}
              options={shareWithUserOptions}
              value={toUserId || null}
              onChange={(e) => setToUserId(Number(String(e)))}
            />
          </FormControl>
          {leader.primary_assistant && (
            <FormControl fullWidth sx={{marginTop: 1}}>
              <FormLabel>Set Current User as Backup</FormLabel>
              <CabControlLabel
                sx={{marginTop: 1}}
                label={
                  `${leader.primary_assistant.first_name} ${leader.primary_assistant.last_name} 
                  (${leader.primary_assistant.email})`
                }
                labelPlacement="end"
                control={<CabCheckbox
                  checked={shareToUser}
                  onChange={(e, c) => setShareToUser(c)}
                />}
              />
            </FormControl>
          )}
        </Box>
      </CabModal>
    );
  };
  

export default AdminReassignLeader;