import { CabModal } from "../../../components/CabComponents/CabModal";
import { CabButton } from "../../../components/CabComponents/CabButton";
import { Box, Grid, Link, styled, Typography } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { SubscriptionChange, SubscriptionDetails } from "../../../store";
import { BILLING_INTERVAL, EVENT_TYPE, TIER, NEW_TIER, NEW_TIER_DETAILS } from "../../../constants";
import { capitalize } from "lodash-es";
import { DateTime } from "luxon";
import { useDebouncedCallback } from "use-debounce";
import { trackEventWithExtra } from "../../../utils/appAnalyticsUtils";
import { CabTextInput } from "@CabComponents/CabTextInput";

interface Props {
  onClose: () => void;
  newSubscriptionDetails?: SubscriptionChange | null;
  onChangeSubscription: (
    newTier: TIER | null, interval: BILLING_INTERVAL | null, quantity: number | null, 
    newPromoCode: string | null, isPreview: boolean, prorationDate: string | null
  ) => Promise<SubscriptionDetails | undefined>;
  buttonDisabled: boolean;
  dialogError: string;
  onLicenseChangeSuccess: () => void;
}

interface LineItemDetails {
  description: string;
  amount: string;
}

const formatCurrency = (amount: number) => {
  return (amount / 100).toFixed(2);
};

export const SubChangeConfirm = ({
  onClose, newSubscriptionDetails, onChangeSubscription, buttonDisabled, dialogError,
  onLicenseChangeSuccess
}: Props): ReactElement => {
  const [open, setOpen] = useState(false);
  const [pendingSubscriptionDetails, setPendingSubscriptionDetails] = useState<SubscriptionDetails | undefined>();
  const [isSuccess, setIsSuccess] = useState(false);
  const [newPromoCode, setNewPromoCode] = useState("");

  const changeSubscription = async (isPreview: boolean) => {
    if (!newSubscriptionDetails) {
      return;
    }
    const {tier, interval, quantity} = newSubscriptionDetails;
    const prorationDate = pendingSubscriptionDetails?.changes_preview?.proration_date ?? null;
    const changeSubscriptionCallback = () => onChangeSubscription(
      tier, interval, quantity, newPromoCode, isPreview, prorationDate
    );

    // Don't track the subscription confirmation event if it's just the preview
    if (isPreview) {
      const previewSubscriptionDetails = await changeSubscriptionCallback();
      setPendingSubscriptionDetails(previewSubscriptionDetails);
      if (previewSubscriptionDetails !== undefined) {
        setOpen(true);
      }
    } else {
      trackEventWithExtra({
        eventName: EVENT_TYPE.SUBSCRIPTION_CHANGED,
        extra: {tier, interval, quantity}
      }, async () => {
        await changeSubscriptionCallback();
        onLicenseChangeSuccess();
        setIsSuccess(true);
      });
    }
  };

  const onConfirmSubscriptionChange = () => changeSubscription(false);

  const previewSubscription = () => changeSubscription(true);

  // Debounce this so we make sure we have all subscription information before showing the confirmation
  const previewSubscriptionDebounced = useDebouncedCallback(previewSubscription, 500);

  useEffect(() => {
    previewSubscriptionDebounced();
  }, [newSubscriptionDetails, previewSubscriptionDebounced]);

  const nextCycleStartIsoStr = pendingSubscriptionDetails?.changes_preview?.next_cycle?.period.start;
  const nextCycleStartDate = nextCycleStartIsoStr ? DateTime.fromISO(nextCycleStartIsoStr) : null;
  const nextCycleStartDateStr = nextCycleStartDate ? nextCycleStartDate.toLocaleString() : "";

  // const nextCycleEndIsoStr = pendingSubscriptionDetails?.changes_preview?.next_cycle?.period.end;
  // const nextCycleEndDate = nextCycleEndIsoStr ? DateTime.fromISO(nextCycleEndIsoStr) : null;
  // const nextCycleEndDateStr = nextCycleEndDate ? nextCycleEndDate.toLocaleString() : "";

  // +DateTime is the syntax required to compare the timestamp of the datetimes rather than an exact object match
  const billToday = nextCycleStartDate && +nextCycleStartDate?.startOf("day") === +DateTime.now().startOf("day");
  const nextBillDateStr = billToday ? "Today" : nextCycleStartDateStr ? "on " + nextCycleStartDateStr : "";
  const newTier = pendingSubscriptionDetails?.tier || NEW_TIER.BASIC;
  
  const discountTotal = pendingSubscriptionDetails?.changes_preview?.next_cycle?.discount_total || 0;
  const taxTotal = pendingSubscriptionDetails?.changes_preview?.next_cycle?.tax_total || 0;
  const subTotal = pendingSubscriptionDetails?.changes_preview?.next_cycle?.amount_excluding_tax || 0;
  const newPriceAmount = subTotal - discountTotal + taxTotal;

  const newPriceInterval = pendingSubscriptionDetails?.changes_preview?.next_cycle?.interval 
                            || pendingSubscriptionDetails?.interval
                            || "";
  const newQuantity = pendingSubscriptionDetails?.quantity || 0;
  
  const newPriceAmountStr = newPriceAmount ? `$${formatCurrency(newPriceAmount)} / ${newPriceInterval}` : "";

  const prorations = pendingSubscriptionDetails?.changes_preview?.prorations;
  const prorationTotal = prorations && prorations.length > 0 
    ? prorations.map(p => p.amount).reduce((a, b) => a + b) 
    : 0;
  const prorationTotalStr = `${prorationTotal < 0 ? '+' : ''}$${formatCurrency(Math.abs(prorationTotal))}`;

  const trialEndDateStr = pendingSubscriptionDetails?.trial_end;
  const trialEndDate = trialEndDateStr ? DateTime.fromISO(trialEndDateStr) : null;
  const trialEndDateDisplay = trialEndDate && trialEndDate > DateTime.now() ? trialEndDate.toLocaleString() : null;
  const trialEndPriceKey = newPriceInterval === "month" ? "monthlyPrice" : "annualPrice";
  const trialEndPriceAmount = (
    NEW_TIER_DETAILS[newTier] ? (NEW_TIER_DETAILS[newTier][trialEndPriceKey] * newQuantity - 
      (discountTotal / 100) + (taxTotal / 100)) : 0
  );
  const trialEndPrice = `$${trialEndPriceAmount.toFixed(2)} / ${newPriceInterval}`;
  const newPriceBillDateDisplay = trialEndDateDisplay ?? nextBillDateStr;

  const prorationDetails: LineItemDetails[] | undefined = prorations?.map(p => {
    const amountStr = `${p.amount < 0 ? '+' : '-'}$${formatCurrency(Math.abs(p.amount))}`;

    return {description: p.description, amount: amountStr};
  });

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const handleChangePromoCode = (v: string) => {
    setNewPromoCode(v.toUpperCase());
  };

  return (
    <CabModal
      open={open}
      onClose={handleClose}
      title={"Confirm Changes"}
      aria-labelledby='Subscription Change Confirmation Dialog'
      aria-describedby='Subscription Change Confirmation Dialog'
      actionButtons={<Box display="flex" justifyContent="space-between" width={"100%"}>
        <CabButton buttonType='tertiary' color='primary' disabled={buttonDisabled || isSuccess}
          onClick={handleClose}>
          Cancel
        </CabButton>
        <CabButton buttonType='primary' color='primary' disabled={buttonDisabled || isSuccess}
          onClick={onConfirmSubscriptionChange}>
          Confirm
        </CabButton>
      </Box>}
    >
      {pendingSubscriptionDetails &&
        <Grid container rowSpacing={1}>
          <Grid item xs={12}>
            <LineItem 
              label="Selected Plan" 
              value={NEW_TIER_DETAILS[newTier]?.title}
            />
          </Grid>
          <Grid item xs={12}>
            <LineItem 
              label="Billing Interval"
              value={`${capitalize(pendingSubscriptionDetails.interval)}ly`}
            />
          </Grid>
          <Grid item xs={12}>
            <LineItem 
              label="Number of Seats" 
              value={`${pendingSubscriptionDetails.quantity}`}
            />
          </Grid>
          {newPriceBillDateDisplay &&
            <Grid item xs={12} sx={{marginTop: 2}}>
              <LineItem 
                label={`New Price (Billed ${newPriceBillDateDisplay})`}
                value={trialEndDateDisplay ? trialEndPrice : newPriceAmountStr}
              />
            </Grid>
          }
          <Grid item xs={12}>
            <LineItem 
              label={prorationTotal < 0 ? "Account Credit" : "Due Today"}
              value={prorationTotalStr}
              details={prorationDetails}
            />
          </Grid>
          <Grid item xs={12} marginTop="12px">
            <Box display="flex" justifyContent="flex-start">
              <CabTextInput 
                label="Promo Code" 
                value={newPromoCode}
                onChange={(v) => handleChangePromoCode(v.target.value)}
                sx={{
                  width: "200px",
                  "& fieldset": {
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  }
                }}
              />
              <CabButton color="secondary"
                onClick={previewSubscription}
                sx={{
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
              >
                Apply
              </CabButton>
            </Box>
          </Grid>
        </Grid>
      }
      {isSuccess &&
        <Typography variant='h4' textAlign={"center"} color="primary" width={"100%"} marginTop={"24px"}>
          Subscription changed successfully!
        </Typography>
      }
      
      {dialogError && !buttonDisabled &&
        <Typography variant='body1' color="error" marginTop={"8px"}>
          {dialogError}
        </Typography>
      }
    </CabModal>
  );
};

interface LineItemProps {
  label: string;
  value: string;
  details?: LineItemDetails[];
}

const LineItem = ({label, value, details}: LineItemProps) => {
  const [showDetails, setShowDetails] = useState(false);
  const hasDetails = details && details.length > 0;

  return (
    <Grid item xs={12}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="body1">
          {label}
          {hasDetails && " "}
          {hasDetails && (
            showDetails 
              ? <StyledLink onClick={() => setShowDetails(false)}>(Hide Details)</StyledLink> 
              : <StyledLink onClick={() => setShowDetails(true)}>(Show Details)</StyledLink>
          )}
          :
        </Typography>
        <Typography variant="body1">
          {value}
        </Typography>
      </Box>
      {hasDetails && showDetails && (
        <ul>
          {details.map((d, i) => (
            <li key={i}>
              <Box component={"span"} display="flex" justifyContent="space-between">
                <Typography variant="body1">
                  {d.description}
                </Typography>
                <Typography variant="body1" marginLeft={8}>
                  {d.amount}
                </Typography>
              </Box>
            </li>
          ))}
        </ul>
      )}
    </Grid>
  );
};

const StyledLink = styled(Link, {label: "StyledLink"})(({theme}) => ({
  color: "purple",
  '&:hover': {
    cursor: "pointer"
  }
}));