import { GridColDef, GridRenderCellParams, gridStringOrNumberComparator } from "@mui/x-data-grid-pro";
import { ReactNode, useMemo } from "react";
import { SharedLeaderGrant, User } from "../../../../store";
import { DateTime } from "luxon";
import { CabIcon, ICabIcon } from "@CabComponents/CabIcon";
import { CabTooltip, ICabTooltip } from "@CabComponents/CabTooltip";
import colors from "../../../../colors";
import BackupMenu from "./BackupMenu/BackupMenu";
import { CabCheckbox } from "@CabComponents/CabCheckbox";


export const StyleCabIcon = (props: ICabIcon & {granted: boolean}) => {
  return <CabIcon
    {...props}
    sx={{color: props.granted ? undefined : colors.black400, fontSize: 12, width: 18, height: 18}}
  />;
};


export const StyleCabTooltip = (props: ICabTooltip & {granted: boolean, title: string}) => {
  return <CabTooltip
    {...props}
    title={props.title + (props.granted ? " [Granted]" : " [Not Granted]")}
    sx={{marginRight: 0.5}}
  />;
};


export const useBackupTableColumns = ({handleOpenOtherPermissions, revokeGrant, user}: {
  user: User | undefined | null
  handleOpenOtherPermissions: (grant: SharedLeaderGrant) => void,
  revokeGrant: (grant: SharedLeaderGrant) => void
}) => {
  
  return useMemo<GridColDef<SharedLeaderGrant>[]>(() => {
    const cols: GridColDef<SharedLeaderGrant>[] = [
      {
        headerName: 'Backup',
        type: "string",
        field: 'a',
        align: "left",
        width: 400,
        display: "flex",
        editable: false,
        sortable: true,
        sortComparator: (v1:SharedLeaderGrant["to_user"], v2:SharedLeaderGrant["to_user"], param1, param2) => {
          return gridStringOrNumberComparator(
            v1.first_name,
            v2.first_name,
            param1,
            param2,
          );
        },
        renderCell: (props: GridRenderCellParams<SharedLeaderGrant, ReactNode>) => <>
          {props.row.to_user.first_name} {props.row.to_user.last_name} ({props.row.to_user.email})
        </>
      },
      {
        headerName: 'Start Date',
        type: "string",
        field: 'start_date',
        align: "left",
        display: "flex",
        width: 100,
        editable: false,
        sortable: true,
        renderCell: (props: GridRenderCellParams<SharedLeaderGrant, ReactNode>) => <>
          {DateTime.fromISO(props.row.start_date).toLocaleString(DateTime.DATE_SHORT)}
        </>
      },
      {
        headerName: 'End Date',
        type: "string",
        field: 'end_date',
        align: "left",
        width: 100,
        display: "flex",
        editable: false,
        sortable: true,
        renderCell: (props: GridRenderCellParams<SharedLeaderGrant, ReactNode>) => <>
          { props.row.end_date ? 
            DateTime.fromISO(props.row.end_date).toLocaleString(DateTime.DATE_SHORT)
            :
            "Forever"
          }
        </>
      },
      {
        headerName: 'Full Access',
        type: "string",
        field: 'is_admin',
        align: "center",
        width: 100,
        display: "flex",
        editable: false,
        sortable: true,
        renderCell: (props: GridRenderCellParams<SharedLeaderGrant, ReactNode>) => <>
          <CabCheckbox
            checked={props.row.is_admin}
          />
        </>
      },
      {
        headerName: 'See More',
        type: "string",
        headerAlign: "center",
        field: 'see_more',
        align: "center",
        width: 200,
        display: "flex",
        editable: false,
        sortable: true,
        renderCell: (props: GridRenderCellParams<SharedLeaderGrant, ReactNode>) => <>
          <BackupMenu
            user={user}
            grant={props.row}
            handleOpenOtherPermissions={handleOpenOtherPermissions}
            revokeGrant={revokeGrant}
          />
        </>
      }
    ];

    return cols;
  }, [handleOpenOtherPermissions, revokeGrant, user]);
};
