import { ReactElement, useState } from 'react';
import { Box } from '@mui/material';
import { 
  User
} from '../../../store';
import { useForm } from 'react-hook-form';

import { CabToggleChip } from '@CabComponents/CabToggleChip';
import colors from '../../../colors';
import { CabIcon } from '@CabComponents/CabIcon';
import { IoAddOutline } from 'react-icons/io5';
import { CabModal } from '@CabComponents/CabModal';
import { CabButton } from '@CabComponents/CabButton';


import { EventLabelAbstractFormInput } from '../../../components/EventAnalytics/Modals/EventLabelAbstractModal';
import {
  CreateEventCategoryModal
} from '../../../components/EventAnalytics/Modals/CreateEventCategoryModal/CreateEventCategoryModal';
import { CompanyCategory } from '../../../store/cabinetApi/generated/crm';
import { DEFAULT_LEADER_LABEL_COLORS } from '../../../constants';


interface Props {
  organizationContactCategories: { [key: number]: CompanyCategory };
  user: User;
  createLabel: (
    data: CompanyCategory, 
  ) => Promise<void>
  updateLabel: (
    data: CompanyCategory, 
  ) => Promise<void>
  saving: boolean;
  deleteLabel: (data: CompanyCategory) => Promise<void>;
}



export const PeopleTags = ({
  organizationContactCategories, user, createLabel, updateLabel, saving, deleteLabel
}: Props): ReactElement => {

  const [selectedTag, setSelectedTag] = useState(-1);
  const [openAddEditModal, setOpenAddEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const { control, reset, getValues, setValue, formState } = useForm<EventLabelAbstractFormInput>({
    defaultValues: {
      id: -1,
      name:  "",
      color: colors.black200,
      organization: user?.active_license.organization
    }
  });

  const modalTitleStr = selectedTag > 0 ? 'Edit' : 'Create';
  const buttonStr = selectedTag > 0 ? 'Save' : 'Create';
  const modalVariationStr = ' Organization Category';

  const categories = Object.values(organizationContactCategories);


  const createNewCategory = () => {
    reset();
    setSelectedTag(-1);
    setOpenAddEditModal(true);
  };

  const editCategory = (category: CompanyCategory) => {
    setSelectedTag(category.id);
    setValue('id', category.id);
    setValue('name', category.name);
    setValue('color', category.color ?? DEFAULT_LEADER_LABEL_COLORS[0]);
    setOpenAddEditModal(true);
  };

  const submitCleanup = () => {
    setOpenDeleteModal(false);
    setOpenAddEditModal(false);
    setSelectedTag(-1);
    reset();
  };

  const labelSorter = (a:CompanyCategory, b: CompanyCategory) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  };

  const onConfirmClick = async () => {
    if (selectedTag < 0) {
      await createLabel(getValues());
    } else {
      await updateLabel(getValues());
    }
    submitCleanup();
  };

  return (
    <>
      <Box display='flex' flexDirection='column'>
        <Box display='flex' flexDirection='column' gap={2} padding={1.25}>
          <Box display='flex' justifyContent='space-between'>
            <div></div>
            <CabToggleChip
              label={<Box display='flex' alignItems='center'>
                <CabIcon Icon={IoAddOutline} sx={{fontSize: 20}} />
                New
              </Box>}
              chipColor={colors.black200}
              translateColor
              selected
              onClick={createNewCategory}
              sx={{marginLeft: '16px'}}
            />
          </Box>
          <Box display='flex' flexWrap='wrap' gap={1}>
            {categories.sort(labelSorter).map(category => {
              return <CabToggleChip
                key={category.id}
                label={category.name}
                chipColor={category.color}
                translateColor
                selected
                onClick={() => editCategory(category)}
              />;
            })}
          </Box>
        </Box>
      </Box>

      <CabModal
        open={openAddEditModal}
        onClose={() => setOpenAddEditModal(false)}
        closeIcon={true}
        title={modalTitleStr + modalVariationStr}
        actionButtons={
          <Box width='100%' display='flex' justifyContent='space-between'>
            {selectedTag > 0 && (
              <CabButton
                buttonType="tertiary"
                onClick={() => setOpenDeleteModal(true)}
              >
                Delete
              </CabButton>
            )}
            <Box gap={1} display='flex' width='100%' justifyContent='flex-end'>
              <CabButton
                buttonType="tertiary"
                onClick={() => setOpenAddEditModal(false)}
              >
                Cancel
              </CabButton>
              <CabButton
                disabled={saving || !formState.isValid}
                onClick={onConfirmClick}
              >
                {buttonStr} Category
              </CabButton>
            </Box>
          </Box>
        }
      >
        <CreateEventCategoryModal
          control={control}
        />
      </CabModal>

      <CabModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        isAlert={true}
        title={'Are you sure?'}
        noFullScreen={true}
        text={`Removing this category will permanently remove it 
        from all events for all users in your organization. Are you sure you want to remove it?`}
        actionButtons={
          <>
            <CabButton buttonType='tertiary' color='primary' onClick={() => setOpenDeleteModal(false)}>
              No
            </CabButton>
            <CabButton 
              onClick={() => deleteLabel(getValues()).then(() => submitCleanup())}
            >
              Yes, delete it
            </CabButton>
          </>
        }
      />
    </>
  );
};
  
export default PeopleTags;